<template>
  <div class="outer-page">
    <!-- <div class="sub-section">
      <div class="warpper">
        <div class="sub-info">
          <div v-html="groupInfo.articleContent"></div>
        </div>
      </div>
    </div> -->
    <article-info></article-info>
  </div>
</template>

<script>
import articleInfo from "@components/articleInfo"
export default {
  name: "",
  components: {
    articleInfo,
  },
  data() {
    return {
      // groupInfo: [],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>